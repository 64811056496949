// ====== APPLICATION CONSTANTS ====== //
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const VERSION = process.env.REACT_APP_VERSION;
export const MODE = process.env.REACT_APP_MODE;
export const EMAIL_ADDRESS = "info@gasolinasa.com";
export const PHONE_NO = "+27 987 654 3210";
export const ADDRESS =
	"34 The Avenue Road, Gardens, Johannesburg, Gauteng, 2192";

// ====== Constants ====== //

export const TAGLINE = "Fuel Up with Ease.";
export const PLAY_STORE_URL =
	"https://play.google.com/store/apps/details?id=com.gasolinamobileapp";
export const APP_STORE_URL =
	"https://apps.apple.com/in/app/gasolina-fuel-up-with-ease/id6737405628";
